<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded">
                <div uk-grid>
                    <div class="uk-width-1-2">
                        <div class="uk-flex">
                            <div class="uk-width-expand"><h4>Jumlah Mitra per Kota</h4></div>
                        </div>
                        <div uk-spinner class="uk-flex-inline uk-width-expand" v-show="loadingMitraOnCity"></div>
                        <div v-if="mitra_per_city != null && !loadingMitraOnCity">
                            <div class="uk-flex-inline uk-width-expand" v-for="(data, i) in mitra_per_city" :key="i" >
                                <div class="uk-width-1-2">{{data.city}}</div>
                                <div class="uk-width-auto">: {{data.count}} Orang</div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-2">
                        <div class="uk-flex">
                            <div class="uk-width-expand"><h4>Jumlah Candidate per Domisili</h4></div>
                        </div>
                        <div uk-spinner class="uk-flex-inline uk-width-expand" v-show="loadingCandidateOnCity"></div>
                        <div v-if="candidate_per_city != null && !loadingCandidateOnCity">
                            <div class="uk-flex-inline uk-width-expand" v-for="(data, x) in candidate_per_city" :key="x">
                                <div class="uk-width-expand">{{data.city}}</div>
                                <div class="uk-width-auto">: {{formatCurrency(data.count)}} Orang</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import formatter from "@/utils/formatter";

moment.locale('id');

export default {
    data() {
        return {
            mitra_per_city: null,
            candidate_per_city: null,
            loadingMitraOnCity: false,
            loadingCandidateOnCity: false
        };
    },
    mounted() {
        Promise.all([
            this.setDataCandidatePerCity(),
            this.setDataMitraPerCity()
        ]);
    },
    methods: {
        ...mapActions({
            getMitraOnCity: 'dashboard/getMitraOnCity',
            getCandidateOnCity: 'dashboard/getCandidateOnCity'
        }),
        async setDataCandidatePerCity(){
            this.loadingCandidateOnCity = true;
            this.candidate_per_city = await this.getCandidateOnCity();
            this.loadingCandidateOnCity = false;
        },
        async setDataMitraPerCity() {
            this.loadingMitraOnCity = true;
            this.mitra_per_city = await this.getMitraOnCity();
            this.loadingMitraOnCity = false;
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
    },
};
</script>
